$fontFamily: Inter;
$colorFont: #1e212e;
$fontSize: 16px;
$lineHeight: 150%;
$index: calc(1vh + 1vw);
$maxWidthContainer: 1246px;

$colorMain: #ef4123;
$dark1000: #1e212e;
$dark900: #1e212e;
$dark800: #4b4d58;
$dark700: #62646d;
$dark600: #787a82;
$dark500: #8e8f96;
$dark400: #a5a6ab;
$dark300: #bcbdc1;
$dark200: #d2d3d5;
$dark100: #e9e9eb;
$dark50: #f3f4f4;
$orange1000: #341813;
$orange900: #5F251B;
$orange800: #902E1D;
$orange700: #C5341B;
$orange600: #FB654B;
$orange500: $colorMain;
$orange400: #FB654B;
$orange300: #FF8E7A;
$orange200: #FFB9AD;
$orange100: #FFE5E0;
