@font-face {
    font-family: Inter;
    font-weight: 400;
    font-style: normal;
    font-display: "swap";
    src: url("../../fonts/Inter-Regular.woff") format("woff"), url("../../fonts/Inter-Regular.woff2") format("woff2");
}

@font-face {
    font-family: Inter;
    font-weight: 400;
    font-style: italic;
    font-display: "swap";
    src: url("../../fonts/Inter-Italic.woff") format("woff"), url("../../fonts/Inter-Italic.woff2") format("woff2");
}

@font-face {
    font-family: Inter;
    font-weight: 500;
    font-style: normal;
    font-display: "swap";
    src: url("../../fonts/Inter-Medium.woff") format("woff"), url("../../fonts/Inter-Medium.woff2") format("woff2");
}

@font-face {
    font-family: Inter;
    font-weight: 600;
    font-style: normal;
    font-display: "swap";
    src: url("../../fonts/Inter-SemiBold.woff") format("woff"), url("../../fonts/Inter-SemiBold.woff2") format("woff2");
}

@font-face {
    font-family: Inter;
    font-weight: 700;
    font-style: normal;
    font-display: "swap";
    src: url("../../fonts/Inter-Bold.woff") format("woff"), url("../../fonts/Inter-Bold.woff2") format("woff2");
}
