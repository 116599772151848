@import "../base/variables";

.arrow {
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out 0s;
    background: $colorMain;
    color: #fff;
    border: 1px solid transparent;

    @media (max-width: 576px) {
        padding: 10px;
        width: 40px;
        height: 40px;
    }

    &:hover {
        color: $colorMain;
        background: #fff;
        border-color: $colorMain;
    }
}
