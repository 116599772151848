@import "../base//functions";

.button {
    padding: 12px 24px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: $orange100;
    font-size: 16px;
    line-height: toProcent(16, 24);
    font-weight: 600;
    color: #fff;
    background: $dark1000;
    transition: all 0.3s ease-in-out 0s;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

    &:hover {
        background: $colorMain;
    }

    &--border {
        border: 1px solid $dark500;
        color: $dark500;
        background: transparent;

        &:hover {
            border-color: $colorMain;
            color: #fff;
        }
    }

    &--gray {
        color: $dark500;
    }

    &__icon {
        width: 24px;
        aspect-ratio: 1/1;
        flex: none;
        display: flex;
        align-items: center;

        & svg {
            width: 100%;
            height: 100%;
        }
    }

    &__text {
    }
}
