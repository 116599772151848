@import "../../assets//scss/base/variables";
@import "../../assets//scss/base/functions";

.project-card {
    &__category {
        margin-bottom: 4px;
        font-size: 14px;
        line-height: toProcent(14, 20);
        color: $dark400;
        font-weight: 600;
    }

    &__name {
        margin-bottom: 24px;
    }

    &__image {
        margin-bottom: 24px;
        aspect-ratio: 384/216;
        border-radius: 8px;
        overflow: hidden;
        display: block;

        &:hover{
            & img{
                transform: scale(1.05);
            }
        }

        & img {
            height: 100%;
            transition: all 0.3s ease-in-out 0s;
        }
    }

    &__button {
        width: 100%;
    }
}
